// apollo-client.ts

import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import AppSyncConfig from '../config/AppSyncConfig';

const httpLink = new HttpLink({
  uri: AppSyncConfig.API.GraphQL.endpoint,
  headers: {
    'x-api-key' : AppSyncConfig.API.GraphQL.apiKey
  }
});

const ApolloAppSyncClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

export default ApolloAppSyncClient;
