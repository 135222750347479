import { Typography, Paper, Grid, Divider, Avatar, Stack, Box} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState} from "react";


function Testimonials() {
    const { t } = useTranslation();
    
    const [testimonialStyle] = useState({
        padding: "16px", 
        backgroundColor: "#fafafa"
    });

    return (        
        <Grid container spacing={5} sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <Grid item xs={12} lg={6} sx={{ display: 'flex' }}>             
                <Paper sx={testimonialStyle}> 
                    <Stack direction="row" spacing={2}>
                        <Avatar src="/images/testimonials/kanam_achen.jpg" sx={{width: 100, height: 100}} />  
                        <Box>
                            <Typography variant="h6">{t('kanamachen_title1', {ns: 'testimonials'})}</Typography>
                            <Typography variant="subtitle1">{t('kanamachen_title2', {ns: 'testimonials'})}</Typography>
                        </Box>
                    </Stack>    
                    <Divider style={{marginTop: "10px", marginBottom: "10px"}}></Divider> 
                    <Typography component="pre" variant="body2" 
                                sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>   
                        {t('kanamachen_message', {ns: 'testimonials'})}
                    </Typography>
                </Paper>
            </Grid>

            <Grid item xs={12} lg={6} sx={{ display: 'flex' }}>         
                <Paper sx={testimonialStyle}> 
                    <Stack direction="row" spacing={2}>
                        <Avatar src="/images/testimonials/ebby_mammen.jpg" sx={{width: 100, height: 100}} />  
                        <Box>
                            <Typography variant="h6">{t('ebbymammen_title1', {ns: 'testimonials'})}</Typography>
                            <Typography variant="subtitle1">{t('ebbymammen_title2', {ns: 'testimonials'})}</Typography>
                        </Box>
                    </Stack>    
                    <Divider style={{marginTop: "10px", marginBottom: "10px"}}></Divider> 
                    <Typography component="pre" variant="body2" 
                                sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>   
                        {t('ebbymammen_message', {ns: 'testimonials'})}
                    </Typography>
                </Paper>
            </Grid>

            <Grid item xs={12} lg={6} sx={{ display: 'flex' }}>         
                <Paper sx={testimonialStyle}> 
                    <Stack direction="row" spacing={2}>
                        <Avatar src="/images/testimonials/thomas_madden.jpg" sx={{width: 100, height: 100}} />  
                        <Box>
                            <Typography variant="h6">{t('thomas_madden_title1', {ns: 'testimonials'})}</Typography>
                            <Typography variant="subtitle1">{t('thomas_madden_title2', {ns: 'testimonials'})}</Typography>
                        </Box>
                    </Stack>    
                    <Divider style={{marginTop: "10px", marginBottom: "10px"}}></Divider> 
                    <Typography component="pre" variant="body2" 
                                sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>   
                        {t('thomas_madden_message', {ns: 'testimonials'})}
                    </Typography>
                </Paper>
            </Grid>

            <Grid item xs={12} lg={6} sx={{ display: 'flex' }}>         
                <Paper sx={testimonialStyle}> 
                    <Stack direction="row" spacing={2}>
                        <Avatar src="/images/testimonials/k_m_thankachan.jpg" sx={{width: 100, height: 100}} />  
                        <Box>
                            <Typography variant="h6">{t('kmthankachan_title1', {ns: 'testimonials'})}</Typography>
                            <Typography variant="subtitle1">{t('kmthankachan_title2', {ns: 'testimonials'})}</Typography>
                        </Box>
                    </Stack>    
                    <Divider style={{marginTop: "10px", marginBottom: "10px"}}></Divider> 
                    <Typography component="pre" variant="body2" 
                                sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>   
                        {t('kmthankachan_message', {ns: 'testimonials'})}
                    </Typography>
                </Paper>
            </Grid>

            <Grid item xs={12} lg={6} sx={{ display: 'flex' }}>         
                <Paper sx={testimonialStyle}> 
                    <Stack direction="row" spacing={2}>
                        <Avatar src="/images/testimonials/victor_george.jpg" sx={{width: 100, height: 100}} />  
                        <Box>
                            <Typography variant="h6">{t('victorgeorge_title1', {ns: 'testimonials'})}</Typography>
                            <Typography variant="subtitle1">{t('victorgeorge_title2', {ns: 'testimonials'})}</Typography>
                        </Box>
                    </Stack>    
                    <Divider style={{marginTop: "10px", marginBottom: "10px"}}></Divider> 
                    <Typography component="pre" variant="body2" 
                                sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>   
                        {t('victorgeorge_message', {ns: 'testimonials'})}
                    </Typography>
                </Paper>
            </Grid>

            <Grid item xs={12} lg={6} sx={{ display: 'flex' }}>         
                <Paper sx={testimonialStyle}> 
                    <Stack direction="row" spacing={2}>
                        <Avatar src="/images/testimonials/p_j_james.jpg" sx={{width: 100, height: 100}} />  
                        <Box>
                            <Typography variant="h6">{t('pjjames_title1', {ns: 'testimonials'})}</Typography>
                            <Typography variant="subtitle1">{t('pjjames_title2', {ns: 'testimonials'})}</Typography>
                        </Box>
                    </Stack>    
                    <Divider style={{marginTop: "10px", marginBottom: "10px"}}></Divider> 
                    <Typography component="pre" variant="body2" 
                                sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>   
                        {t('pjjames_message', {ns: 'testimonials'})}
                    </Typography>
                </Paper>
            </Grid>

            <Grid item xs={12} lg={6} sx={{ display: 'flex' }}>         
                <Paper sx={testimonialStyle}> 
                    <Stack direction="row" spacing={2}>
                        <Avatar src="/images/testimonials/john_cherian.jpg" sx={{width: 100, height: 100}} />  
                        <Box>
                            <Typography variant="h6">{t('johncherian_title1', {ns: 'testimonials'})}</Typography>
                            <Typography variant="subtitle1">{t('johncherian_title2', {ns: 'testimonials'})}</Typography>
                        </Box>
                    </Stack>    
                    <Divider style={{marginTop: "10px", marginBottom: "10px"}}></Divider> 
                    <Typography component="pre" variant="body2" 
                                sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>   
                        {t('johncherian_message', {ns: 'testimonials'})}
                    </Typography>
                </Paper>
            </Grid>

            <Grid item xs={12} lg={6} sx={{ display: 'flex' }}>         
                <Paper sx={testimonialStyle}> 
                    <Stack direction="row" spacing={2}>
                        <Avatar src="/images/testimonials/v_georgekutty.jpg" sx={{width: 100, height: 100}} />  
                        <Box>
                            <Typography variant="h6">{t('vgeorgekutty_title1', {ns: 'testimonials'})}</Typography>
                            <Typography variant="subtitle1">{t('vgeorgekutty_title2', {ns: 'testimonials'})}</Typography>
                        </Box>
                    </Stack>    
                    <Divider style={{marginTop: "10px", marginBottom: "10px"}}></Divider> 
                    <Typography component="pre" variant="body2" 
                                sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>   
                        {t('vgeorgekutty_message', {ns: 'testimonials'})}
                    </Typography>
                </Paper>
            </Grid>

            <Grid item xs={12} lg={6} sx={{ display: 'flex' }}>         
                <Paper sx={testimonialStyle}> 
                    <Stack direction="row" spacing={2}>
                        <Avatar src="/images/testimonials/k_m_varghese.jpg" sx={{width: 100, height: 100}} />  
                        <Box>
                            <Typography variant="h6">{t('kmvarghese_title1', {ns: 'testimonials'})}</Typography>
                            <Typography variant="subtitle1">{t('kmvarghese_title2', {ns: 'testimonials'})}</Typography>
                        </Box>
                    </Stack>    
                    <Divider style={{marginTop: "10px", marginBottom: "10px"}}></Divider> 
                    <Typography component="pre" variant="body2" 
                                sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>   
                        {t('kmvarghese_message', {ns: 'testimonials'})}
                    </Typography>
                </Paper>
            </Grid>

            <Grid item xs={12} lg={6} sx={{ display: 'flex' }}>         
                <Paper sx={testimonialStyle}> 
                    <Stack direction="row" spacing={2}>
                        <Avatar src="/images/testimonials/babu_george.jpg" sx={{width: 100, height: 100}} />  
                        <Box>
                            <Typography variant="h6">{t('babugeorge_title1', {ns: 'testimonials'})}</Typography>
                            <Typography variant="subtitle1">{t('babugeorge_title2', {ns: 'testimonials'})}</Typography>
                        </Box>
                    </Stack>    
                    <Divider style={{marginTop: "10px", marginBottom: "10px"}}></Divider> 
                    <Typography component="pre" variant="body2" 
                                sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>   
                        {t('babugeorge_message', {ns: 'testimonials'})}
                    </Typography>
                </Paper>
            </Grid>

            <Grid item xs={12} lg={6} sx={{ display: 'flex' }}>         
                <Paper sx={testimonialStyle}> 
                    <Stack direction="row" spacing={2}>
                        <Avatar src="/images/testimonials/puthupally_achen.jpg" sx={{width: 100, height: 100}} />  
                        <Box>
                            <Typography variant="h6">{t('puthupallyachen_title1', {ns: 'testimonials'})}</Typography>
                            <Typography variant="subtitle1">{t('puthupallyachen_title2', {ns: 'testimonials'})}</Typography>
                        </Box>
                    </Stack>    
                    <Divider style={{marginTop: "10px", marginBottom: "10px"}}></Divider> 
                    <Typography component="pre" variant="body2" 
                                sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>   
                        {t('puthupallyachen_message', {ns: 'testimonials'})}
                    </Typography>
                </Paper>
            </Grid>

            <Grid item xs={12} lg={6} sx={{ display: 'flex' }}>         
                <Paper sx={testimonialStyle}> 
                    <Stack direction="row" spacing={2}>
                        <Avatar src="/images/testimonials/shibu_thomas.jpg" sx={{width: 100, height: 100}} />  
                        <Box>
                            <Typography variant="h6">{t('shibuthomas_title1', {ns: 'testimonials'})}</Typography>
                            <Typography variant="subtitle1">{t('shibuthomas_title2', {ns: 'testimonials'})}</Typography>
                        </Box>
                    </Stack>    
                    <Divider style={{marginTop: "10px", marginBottom: "10px"}}></Divider> 
                    <Typography component="pre" variant="body2" 
                                sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>   
                        {t('shibuthomas_message', {ns: 'testimonials'})}
                    </Typography>
                </Paper>
            </Grid>

            <Grid item xs={12} lg={6} sx={{ display: 'flex' }}>         
                <Paper sx={testimonialStyle}> 
                    <Stack direction="row" spacing={2}>
                        <Avatar src="/images/testimonials/baby_daniel.jpg" sx={{width: 100, height: 100}} />  
                        <Box>
                            <Typography variant="h6">{t('babydaniel_title1', {ns: 'testimonials'})}</Typography>
                            <Typography variant="subtitle1">{t('babydaniel_title2', {ns: 'testimonials'})}</Typography>
                        </Box>
                    </Stack>    
                    <Divider style={{marginTop: "10px", marginBottom: "10px"}}></Divider> 
                    <Typography component="pre" variant="body2" 
                                sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>   
                        {t('babydaniel_message', {ns: 'testimonials'})}
                    </Typography>
                </Paper>
            </Grid>

            <Grid item xs={12} lg={6} sx={{ display: 'flex' }}>         
                <Paper sx={testimonialStyle}> 
                    <Stack direction="row" spacing={2}>
                        <Avatar src="/images/testimonials/thomas_mammen.jpg" sx={{width: 100, height: 100}} />  
                        <Box>
                            <Typography variant="h6">{t('thomasmammen_title1', {ns: 'testimonials'})}</Typography>
                            <Typography variant="subtitle1">{t('thomasmammen_title2', {ns: 'testimonials'})}</Typography>
                        </Box>
                    </Stack>    
                    <Divider style={{marginTop: "10px", marginBottom: "10px"}}></Divider> 
                    <Typography component="pre" variant="body2" 
                                sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>   
                        {t('thomasmammen_message', {ns: 'testimonials'})}
                    </Typography>
                </Paper>
            </Grid>

            <Grid item xs={12} lg={6} sx={{ display: 'flex' }}>         
                <Paper sx={testimonialStyle}> 
                    <Stack direction="row" spacing={2}>
                        <Avatar src="/images/testimonials/thomas_mullackal.jpg" sx={{width: 100, height: 100}} />  
                        <Box>
                            <Typography variant="h6">{t('thomasmullackal_title1', {ns: 'testimonials'})}</Typography>
                            <Typography variant="subtitle1">{t('thomasmullackal_title2', {ns: 'testimonials'})}</Typography>
                        </Box>
                    </Stack>    
                    <Divider style={{marginTop: "10px", marginBottom: "10px"}}></Divider> 
                    <Typography component="pre" variant="body2" 
                                sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>   
                        {t('thomasmullackal_message', {ns: 'testimonials'})}
                    </Typography>
                </Paper>
            </Grid>

            <Grid item xs={12} lg={6} sx={{ display: 'flex' }}>         
                <Paper sx={testimonialStyle}> 
                    <Stack direction="row" spacing={2}>
                        <Avatar src="/images/testimonials/mathai_samkutty.jpg" sx={{width: 100, height: 100}} />  
                        <Box>
                            <Typography variant="h6">{t('msamkutty_title1', {ns: 'testimonials'})}</Typography>
                            <Typography variant="subtitle1">{t('msamkutty_title2', {ns: 'testimonials'})}</Typography>
                        </Box>
                    </Stack>    
                    <Divider style={{marginTop: "10px", marginBottom: "10px"}}></Divider> 
                    <Typography component="pre" variant="body2" 
                                sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>   
                        {t('msamkutty_message', {ns: 'testimonials'})}
                    </Typography>
                </Paper>
            </Grid>

            <Grid item xs={12} lg={6} sx={{ display: 'flex' }}>         
                <Paper sx={testimonialStyle}> 
                    <Stack direction="row" spacing={2}>
                        <Avatar src="/images/testimonials/thampy_mathew.jpg" sx={{width: 100, height: 100}} />  
                        <Box>
                            <Typography variant="h6">{t('thampymathew_title1', {ns: 'testimonials'})}</Typography>
                            <Typography variant="subtitle1">{t('thampymathew_title2', {ns: 'testimonials'})}</Typography>
                        </Box>
                    </Stack>    
                    <Divider style={{marginTop: "10px", marginBottom: "10px"}}></Divider> 
                    <Typography component="pre" variant="body2" 
                                sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>   
                        {t('thampymathew_message', {ns: 'testimonials'})}
                    </Typography>
                </Paper>
            </Grid>

            <Grid item xs={12} lg={6} sx={{ display: 'flex' }}>         
                <Paper sx={testimonialStyle}> 
                    <Stack direction="row" spacing={2}>
                        <Avatar src="/images/testimonials/satish_kumar.jpg" sx={{width: 100, height: 100}} />  
                        <Box>
                            <Typography variant="h6">{t('satishkumar_title1', {ns: 'testimonials'})}</Typography>
                            <Typography variant="subtitle1">{t('satishkumar_title2', {ns: 'testimonials'})}</Typography>
                        </Box>
                    </Stack>    
                    <Divider style={{marginTop: "10px", marginBottom: "10px"}}></Divider> 
                    <Typography component="pre" variant="body2" 
                                sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>   
                        {t('satishkumar_message', {ns: 'testimonials'})}
                    </Typography>
                </Paper>
            </Grid>

            <Grid item xs={12} lg={6} sx={{ display: 'flex' }}>         
                <Paper sx={testimonialStyle}> 
                    <Stack direction="row" spacing={2}>
                        <Avatar src="/images/testimonials/shibu_thomas_okc.jpg" sx={{width: 100, height: 100}} />  
                        <Box>
                            <Typography variant="h6">{t('shibuthomasokc_title1', {ns: 'testimonials'})}</Typography>
                            <Typography variant="subtitle1">{t('shibuthomasokc_title2', {ns: 'testimonials'})}</Typography>
                        </Box>
                    </Stack>    
                    <Divider style={{marginTop: "10px", marginBottom: "10px"}}></Divider> 
                    <Typography component="pre" variant="body2" 
                                sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>   
                        {t('shibuthomasokc_message', {ns: 'testimonials'})}
                    </Typography>
                </Paper>
            </Grid>

            <Grid item xs={12} lg={6} sx={{ display: 'flex' }}>         
                <Paper sx={testimonialStyle}> 
                    <Stack direction="row" spacing={2}>
                        <Avatar src="/images/testimonials/t_m_kuriachan.jpg" sx={{width: 100, height: 100}} />  
                        <Box>
                            <Typography variant="h6">{t('t_m_kuriachan_title1', {ns: 'testimonials'})}</Typography>
                            <Typography variant="subtitle1">{t('t_m_kuriachan_title2', {ns: 'testimonials'})}</Typography>
                        </Box>
                    </Stack>    
                    <Divider style={{marginTop: "10px", marginBottom: "10px"}}></Divider> 
                    <Typography component="pre" variant="body2" 
                                sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>   
                        {t('t_m_kuriachan_message', {ns: 'testimonials'})}
                    </Typography>
                </Paper>
            </Grid>

        </Grid>
    )
  }
  
  export default Testimonials;