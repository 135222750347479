import { createContext, useReducer, useContext, useEffect } from "react";
import { CognitoUser, AuthenticationDetails, CognitoUserSession } from "amazon-cognito-identity-js";
import Pool from "./UserPool";

const AccountContext = createContext({} as any);

const initialState = {
    isAuthenticated: false,
    user: null,
    isAdmin: false,
};

const authReducer = (state:any, action:any) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        isAdmin: action.payload.username === "president" ? true : false
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        isAdmin: false,
      };
    default:
      return state;
  }
};

function Account(props:any){
    const [state, dispatch] = useReducer(authReducer, initialState);

    const getSession = async () => {
        return await new Promise((resolve, reject) => {
            const user = Pool.getCurrentUser();
            if (user){
                user.getSession((err:any, session:CognitoUserSession) => {
                    if (err) {
                        reject();
                    } else {
                        resolve(session);
                        dispatch({ type: "LOGIN", payload: user });
                    }
                })
            } else {
                //reject();
            }
        })
    };

    const authenticate = async (Username:string, Password:string) => { 
        return await new Promise((resolve, reject) => {
            const user = new CognitoUser({
                Username,
                Pool
            })
    
            const authDetails = new AuthenticationDetails({
                Username,
                Password
            })

            console.log("user: ", user);
    
            user.authenticateUser(authDetails, {
                onSuccess: (data) => {
                    console.log("onSuccess: ", data);
                    dispatch({ type: "LOGIN", payload: user });
                    resolve(data);
                },
                onFailure: (err) => {
                    console.error("onFailure: ", err);
                    reject(err);
                },
                newPasswordRequired: (data) => {
                    console.error("onFailure: ", data);
                    resolve(data);
                }
            });    
        })   
    };

    const logout = () => {
        const user = Pool.getCurrentUser();
        if (user){
            user.signOut();
            dispatch({ type: "LOGOUT" });
        }
    }

    useEffect(() => {
      getSession().catch(() => dispatch({ type: "LOGOUT" }));
    }, []);

    return (
        <AccountContext.Provider value={{...state, authenticate, logout}}>
            {props.children}
        </AccountContext.Provider>
    )
}

export {Account, AccountContext};

export const useAuthState = () => {
  const context = useContext(AccountContext);
  if (context === undefined) {
    throw new Error("useAuthState must be used within an AccountProvider");
  }
  return context;
};

export const useAuthDispatch = () => {
  const context = useContext(AccountContext);
  if (context === undefined) {
    throw new Error("useAuthDispatch must be used within an AccountProvider");
  }
  return context;
};