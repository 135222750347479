import { useState, FormEvent} from "react";
import {Box, TextField, Button, Tooltip, Typography} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import styled from 'styled-components';
import Modal from '@mui/material/Modal';
import { useAuthState, useAuthDispatch } from './Account';
import Alert from '@mui/material/Alert';

const LoginModal = styled(Modal)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
})
function Login(){

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [loginopen, setLoginopen] = useState(false);

    const { isAuthenticated, user } = useAuthState();
    const { authenticate, logout } = useAuthDispatch();

    const handleLoginSubmit = (e:  FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        authenticate(username, password)
            .then((data: any) =>{
                console.log("Logged in!", data);
            })
            .catch((err: any) => {
                console.log("Failed to login", err);
            })
    };

    const handleLogout = () => {
        logout();
        setLoginopen(false);
    };

    return (
        <>
            <Tooltip title ={isAuthenticated ? "Logged in as " + user?.username : ""}>
                <AccountCircleIcon color={isAuthenticated ? "inherit" : "disabled"} fontSize="small" 
                        onClick={e=>setLoginopen(true)} />
            </Tooltip>
            
            <LoginModal
                open={loginopen}
                onClose={e=>setLoginopen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    width={300} height={250} bgcolor="white" p={3} borderRadius={5}>

                    <Typography variant="body1" color="grey" gutterBottom><b>Admin Login</b></Typography> <br/>

                    <Box display={isAuthenticated ? "none" : "block"}>
                        <form onSubmit={handleLoginSubmit}>
                            <TextField id="username" name="username" label="Username" type="text"
                                size="medium" InputLabelProps={{ shrink: true, required: true }}
                                value={username} onChange={(event) => setUsername(event.target.value)}
                            /> <br/><br/>
                            <TextField id="password" name="password" label="Password" type="password"
                                size="medium" InputLabelProps={{ shrink: true, required: true }}
                                value={password} onChange={(event) => setPassword(event.target.value)}
                            /> <br/><br/>
                            <Button variant="contained" type="submit">Submit</Button>
                        </form>
                    </Box>
                    
                    
                    <Box display={isAuthenticated ? "block" : "none"} textAlign="center" p={2}>
                        <Alert severity="success">You are logged in!!</Alert> <br/>
                        <Typography variant="body2"><b>Username:</b> {user?.username}</Typography> <br/>
                        <Button variant="contained" onClick={handleLogout}>Logout</Button>
                    </Box>
                </Box>
            </LoginModal>
        </>
    )
}

export default Login;