import { useQuery } from '@apollo/client';
import {TextField, Button} from "@mui/material"
import { LIST_LESSONS } from "../gql/queries.graphql"
import { ListLessonsQuery, QueryListLessonsArgs} from "../gql/graphql"

function Test() {
    const bookId:string = "TEST45"
    const { loading, error, data } = useQuery<ListLessonsQuery, QueryListLessonsArgs>(
        LIST_LESSONS, 
        {
          variables: {
            filter : {
              book_id: {
                eq: bookId
              }
            }
          }
        }
      );
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
    return (
        <div>
        <h1>Lessons</h1>
        <TextField id="standard-basic" label="Book Name" variant="standard" />
        <Button variant="contained" size="medium">Submit</Button>
        <ul>
          {
            data?.listLessons?.items?.length ? (
              data?.listLessons?.items?.map((lesson: any) => (
              <li key={lesson.lesson_id}>
                Lesson ID: {lesson.lesson_id}, Book ID: {lesson.book_id}
                , From Chapter: {lesson.from_chapter}, From Verse: {lesson.from_verse}
                , To Chapter: {lesson.to_chapter}, To Verse: {lesson.to_verse}
                , S3 URI: {lesson.s3_uri}, Upload Date: {lesson.upload_date}
              </li>
              ))
            ) : (
              <li>No lessons available</li>
            )
          }
        </ul>
      </div>
    )
  }
  
  export default Test;