import {Box, Typography, Stack, Avatar, Button, ButtonGroup} from "@mui/material"
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import LanguageSwitcher from './LanguageSwitcher'
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from 'react-router-dom';
import Login from './Login'

const Sidebar = () => {
    const { t } = useTranslation();

    return (
        <Box flex={1} p={2} position="fixed"
            sx={{ 
                    display:{ xs: "none" , md: "block"}, 
                    background: "url(/images/BlueBackground.jpg)",
                    bgcolor: "#cccccc",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundAttachment: "fixed",
                    color: "white",
                    minWidth: "300px",
                    height: "100%"
                }}>
                
                <LanguageSwitcher />
                <Stack spacing={2} p={2} alignItems="center" textAlign="center" 
                        >
                    <AutoStoriesIcon  sx={{color: "white", height: 100, width: 100}} />
                    <Typography variant="h5">C V ANDREWS <br/> BIBLE</Typography>
                    
                    <Stack spacing={0} sx={{width:"100%", color: "#acc7db", backgroundColor: "#4482ad"}}>
                        <ButtonGroup orientation="vertical" 
                            variant="outlined" size="small" color="inherit">
                            <Button component={RouterLink} to="/cvab">{t("home_page", {ns: 'common'})}</Button>
                            <Button component={RouterLink} to="/cvab/about">{t("about_us", {ns: 'common'})}</Button>
                            <Button component={RouterLink} to="/cvab/testimonials">{t("testimonials", {ns: 'common'})}</Button>
                            <Button component={RouterLink} to="/cvab/contact">{t("contact_us", {ns: 'common'})}</Button>
                        </ButtonGroup>
                    </Stack>

                    <Avatar alt="C V Andrews" src="/images/cvandrews2.jpg" sx={{width: 200, height: 200}} />
                    <Box sx={{maxWidth: "250px"}}>
                        <Typography variant="caption">
                        "{t("cvandrews_message", {ns: 'common'})}"
                        </Typography>
                    </Box>  
                </Stack>
                <Box sx={{position: "absolute", bottom: "35px", left: 1}}>
                    <Login />
                </Box>
        </Box>
    )
}
export default Sidebar