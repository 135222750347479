import About from './components/About';
import Contact from './components/Contact';
import Testimonials from './components/Testimonials';
import Test from './components/Test';
import MainLayout from './components/MainLayout'
import {Routes, Route, Navigate} from 'react-router-dom'
import MainContent from './components/MainContent';
import NoMatchRoute from './components/NoMatchRoute';
import Book from './components/Book';
import { Suspense } from 'react';
import { Account } from './components/Account';
import ScrollToTop from './components/ScrollToTop'; 

function App() {
  return (
    <div className='App'>
      
      <Account>
      <ScrollToTop />
      <Routes>
          <Route path='/' element={<Navigate to='/cvab' replace />}></Route>
          <Route path='cvab' element={<MainLayout />}>
            <Route index element={<MainContent />} />
            <Route path='about' element={<About />}></Route>
            <Route path='contact' element={<Contact />}></Route>
            <Route path='book/:bookId' element={<Book />}></Route>
            <Route path='test' element={<Test />}></Route>
            <Route path='testimonials' element={<Testimonials />}></Route>
          </Route>
          
          <Route path='*' element={<NoMatchRoute />}></Route>
       </Routes>
      </Account>
    </div>
  )
}

export default function WrappedApp() {
  return (
    <Suspense fallback="loading...">
      <App />
    </Suspense>
  );
}
