import { Menu as MenuIcon } from "@mui/icons-material"
import {AppBar, Toolbar, Typography, Menu, MenuItem, Box} from "@mui/material"
import { useState } from 'react';
import LanguageSwitcher from './LanguageSwitcher'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const Navbar = () => {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const goHomeHandler = () => {
        navigate('/cvab');
    }

    const handleMenuItemClick = (path: string) => {
      setOpen(false);
      navigate(path);
    };

    return (
        <AppBar position="sticky" sx={
                {display:{xs:"block", md:"none"},
                background: "url(/images/BlueBackground.jpg)",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover"
            }}>
            <Toolbar>
                <MenuIcon onClick={e=>setOpen(true)} sx={{display:{xs:"block", md:"none"}, cursor: "pointer"}}/>
                <Typography onClick={goHomeHandler} component="div" variant="h6" noWrap p={2} sx={{cursor: "pointer"}}>C V ANDREWS BIBLE</Typography>
                <Box sx={{ flexGrow: 1 }} />
                <LanguageSwitcher />
            </Toolbar>
            
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                open={open}
                onClose={e=>setOpen(false)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => handleMenuItemClick('/cvab')}>{t("home_page", {ns: 'common'})}</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('/cvab/about')}>{t("about_us", {ns: 'common'})}</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('/cvab/testimonials')}>{t("testimonials", {ns: 'common'})}</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('/cvab/contact')}>{t("contact_us", {ns: 'common'})}</MenuItem>
            </Menu>
        </AppBar>
    )
}
export default Navbar