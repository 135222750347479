import {Add} from '@mui/icons-material';
import { useState, ChangeEvent, FormEvent } from "react";
import {Box, Fab, Tooltip, Typography, TextField, Button} from '@mui/material';
import Modal from '@mui/material/Modal';
import styled from 'styled-components';
import Divider from '@mui/material/Divider';
import {CREATE_LESSONS}from "../gql/mutations.graphql"
import {useMutation} from '@apollo/client'
import { CreateLessonsInput } from "../gql/graphql"
import LinearProgress from '@mui/material/LinearProgress';
import { useAuthState } from './Account';
import Alert from '@mui/material/Alert';
import axios from 'axios';

const StyledModal = styled(Modal)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
})

interface FormData {
    from_chapter: string;
    from_verse: string;
    to_chapter: string;
    to_verse: string;
}

enum Status {
    PENDING = 'PENDING',
    IN_PROGRESS = 'IN_PROGRESS',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR'
}

interface AddLessonProps {
    bookId: string;
    maxLessonId: number;
    onUploadSuccess: () => void;
  }

function AddLesson({ bookId, maxLessonId, onUploadSuccess }: AddLessonProps){
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState<File | undefined>();
    const [status, setStatus] = useState<Status>(Status.PENDING);
    const { isAuthenticated, isAdmin, user } = useAuthState();
    
    const lessonId = maxLessonId + 1;
    let audioLessonFileName ="Book_" + bookId + "_" 
        + "Lesson" + lessonId + "_"
        + Math.floor(Math.random() * 10000).toString().padStart(4, '0') + ".mp3";
    let audioLessonFileType = "audio/mpeg";
    let currentDate = new Date().toLocaleDateString('sv');

    const [formData, setFormData] = useState<FormData>({
        from_chapter: '',
        from_verse: '',
        to_chapter: '',
        to_verse: '',
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
          }));
     };
    
     const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement & {
            files: FileList;
        }

        setFile(target.files[0]);
     };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        setStatus(Status.IN_PROGRESS);
        e.preventDefault();
        // Here you can perform any actions with the form data, such as sending it to a server
        if (typeof file === 'undefined' ) return;
        
        console.log(formData);
        console.log(file);
        console.log(audioLessonFileName);

        // Step 1: Get pre-signed URL from your API Gateway

        const response = await axios.get('https://55bhnso6n3.execute-api.us-east-1.amazonaws.com/test/getPresignedUrl', {
            params: {
                fileName: audioLessonFileName,
                fileType: audioLessonFileType
            },
            headers: {
              Authorization: user?.signInUserSession?.idToken?.jwtToken,
            },
          });

        const fileUploadUrl = response.data;
        console.log("fileUploadUrl: ", fileUploadUrl);

        const uploadResponse = await axios.put(fileUploadUrl, file, {
            headers: {
              'Content-Type': audioLessonFileType,
            },
        });

        if (uploadResponse.status === 200) {
            console.log('File uploaded successfully!', audioLessonFileName);
            addLesson();
        } else {
            console.error('File upload failed.', audioLessonFileName);
            setStatus(Status.ERROR);
            return;
        }
        setTimeout(() => { onUploadSuccess(); }, 1000); // 1 second delay
        setStatus(Status.SUCCESS);
    };

    const [createLessons, {error}] = useMutation<CreateLessonsInput>(CREATE_LESSONS)

    const addLesson = () => {
        createLessons({
            variables : {
                input: {
                    book_id: bookId,
                    lesson_id: lessonId,
                    from_chapter: formData.from_chapter === "" ? 0 : parseInt(formData.from_chapter, 10),
                    from_verse: formData.from_verse === "" ? 0 : parseInt(formData.from_verse, 10),
                    to_chapter: formData.to_chapter === "" ? 0 : parseInt(formData.to_chapter, 10),
                    to_verse: formData.to_verse === "" ? 0 : parseInt(formData.to_verse, 10),
                    s3_uri: audioLessonFileName,
                    upload_date: currentDate
                }
            }
        })

        if (error){
            console.log("mutation createLessons error: " + error);
            setStatus(Status.ERROR);
            return;
        }
    }

    function openAddLessonDialog(){
        setStatus(Status.PENDING);
        // Reset the form fields if needed
        setFormData({
            from_chapter: '',
            from_verse: '',
            to_chapter: '',
            to_verse: ''
        });
        setFile(undefined);
        setOpen(true);
    }


    return (
        <>
            <Tooltip onClick={e=>openAddLessonDialog()} title="Add Lesson" 
                sx={{
                        position: "fixed", bottom:20, right: 10,
                        display: isAuthenticated && isAdmin ? "block" : "none"
                    }}>
               <Fab color="primary" aria-label="add">
                    <Add/>
               </Fab>
            </Tooltip>

            <StyledModal
                open={open}
                onClose={e=>setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box width={400} height={350} bgcolor="white" p={3} borderRadius={5}> 
                    <Typography variant="h6" color="grey" textAlign="center">Add Lesson {lessonId}</Typography>
                    <br/>
                    <Divider variant="fullWidth" />
                    <br/>
                    <form onSubmit={handleSubmit}>
                        <Box sx={{display: status === Status.ERROR ? "block" : "none"}} pt={2}>
                            <Alert onClick={() => setStatus(Status.PENDING)} severity="error">Error Occurred during upload.  Click here to try again!!</Alert> <br/>
                        </Box>
                        <Box sx={{display: status === Status.IN_PROGRESS ? "block" : "none"}} pt={2}>
                            <LinearProgress /> <br/>
                            <Typography variant="body2" color="grey" textAlign="center">Please be patient.  Lesson upload In Progress!!!</Typography>
                        </Box>
                        <Box sx={{display: status === Status.SUCCESS ? "block" : "none"}} pt={2}>
                            <Alert severity="success">The lesson was uploaded succesfully!!!!</Alert> <br/>
                        </Box>

                        <Box sx={{display: status === Status.PENDING && lessonId === 1 ? "block" : "none"}} pt={2}>
                            <Alert severity="warning">Please upload the <b><u>introduction</u></b> for {bookId}!!</Alert> <br/>
                        </Box>

                        <Box sx={{display: status === Status.PENDING && lessonId > 1 ? "block" : "none"}} pt={2}>
                            <TextField id="from_chapter" name="from_chapter" label="From Chapter" type="number"
                                size="small" InputLabelProps={{ shrink: true, }}
                                inputProps={{ min: "1", max: "150" }} sx={{width: '100px'}}
                                onChange={handleChange} value={formData.from_chapter}
                            /> 
                            &nbsp;&nbsp;&nbsp;&nbsp;

                            <TextField
                                id="from_verse" name="from_verse" label="From Verse" type="number"
                                size="small" InputLabelProps={{ shrink: true, }}
                                inputProps={{ min: "1", max: "200" }} sx={{width: '100px'}}
                                onChange={handleChange} value={formData.from_verse}
                            />
                        </Box>
                        
                        <Box sx={{display: status === Status.PENDING && lessonId > 1 ? "block" : "none"}} pt={2}>
                            <TextField id="to_chapter" name="to_chapter" label="To Chapter" type="number"
                                size="small" InputLabelProps={{ shrink: true, }}
                                inputProps={{ min: "1", max: "150" }} sx={{width: '100px'}}
                                onChange={handleChange} value={formData.to_chapter}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField id="to_verse" name="to_verse" label="To Verse" type="number"
                                size="small" InputLabelProps={{ shrink: true, }}
                                inputProps={{ min: "1", max: "200" }} sx={{width: '100px'}}
                                onChange={handleChange} value={formData.to_verse}
                            />
                        </Box>
                        <Box sx={{display: status === Status.PENDING ? "block" : "none"}} pt={2}>
                            <TextField id="audio_file" name="audio_file" label="Audio File" type="file"
                                size="small" InputLabelProps={{ shrink: true, }}
                                onChange={handleFileChange}
                            />
                        </Box>
                        
                        <Box sx={{display: status === Status.PENDING ? "block" : "none"}}  pt={2}>
                            <Button variant="contained" type="submit">Save</Button>
                        </Box>
                    </form>
                </Box>
            </StyledModal>
        </>
    )
}

export default AddLesson;