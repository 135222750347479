import {Card, CardMedia, CardContent, Typography, CardActions
        , Grid, Button, Divider} from "@mui/material"
import biblebooks from "../data/biblebooks.json"
import bibleimages from "../data/bibleimages.json"
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from 'react-router-dom';

const MainContent = () => {
    const books = biblebooks.books.filter(obj => obj.sort > 0);
    const { t } = useTranslation();
    return (
        <>
        <Typography variant="body2">                
        {t("welcome_text", {ns: 'common'})}        
        </Typography>

        <Typography pt={2} variant="h5">
        {t("THEBIBLE.name", {ns: 'books'})}  
        </Typography>
        <Typography pt={1} sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word'
                , overflow: "hidden", textOverflow: 'ellipsis', display: '-webkit-box'
                , WebkitLineClamp: '4', WebkitBoxOrient: 'vertical' }}
             component="pre" variant="body2">                
        {t("THEBIBLE.description", {ns: 'books'})}        
        </Typography>
        <Button component={RouterLink} to="/cvab/book/THEBIBLE" size="small">{t("learn_more", {ns: 'common'})}</Button>

        <Divider style={{marginTop: "20px"}}></Divider>
            
            <Grid container spacing={5} style={{marginTop:"10px"}}>
                {
                    books.map((book) => (
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Card >
                                <CardMedia
                                    sx={{ height: 140 }}
                                    image={(bibleimages as { [key: string]: any })[book.id]?(bibleimages as { [key: string]: any })[book.id]:bibleimages.DEFAULT}
                                    title= {t(`${book.id}.imageTitle`, {ns: 'books'})}
                                    />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {t(`${book.id}.name`, {ns: 'books'})}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary"
                                            sx={{
                                                overflow: "hidden",
                                                textOverflow: 'ellipsis',
                                                display: '-webkit-box',
                                                WebkitLineClamp: '3',
                                                WebkitBoxOrient: 'vertical',
                                            }}>
                                        {t(`${book.id}.description`, {ns: 'books'})}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button component={RouterLink} to={`/cvab/book/${book.id}`} size="small">{t("learn_more", {ns: 'common'})}</Button>
                                </CardActions>
                            </Card>
                        </Grid>                 
                    ))
                }     
            </Grid>
        </>
    )
}
export default MainContent