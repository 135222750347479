import {Stack, Link, Typography} from "@mui/material"
import aboutimages from "../data/aboutimages.json"
import { useTranslation } from "react-i18next";

interface ViewImageProps {
    decade: string;
  }

function AboutLinks({ decade }: ViewImageProps) {  
    const { t } = useTranslation();
  const decadeItem = aboutimages.aboutimages.find (item => item.decade === decade)
  
  return (
    <>
    {decadeItem?.links && decadeItem.links.length > 0 && (
      <Stack>
        <Typography pt={2} variant="body2" sx={{ fontWeight: 'bold', textDecoration: 'underline' }}>
        {t('important_links', {ns: 'about'})}:
        </Typography>
        <ul>
          {decadeItem?.links.map((item, index) => (
            <li>
              <Link sx={{ textDecoration: 'none', fontSize: '0.90rem' }} href={`${item.href}`}
                    target="_blank"  rel="noopener noreferrer">
                      {t(`${item.titleKey}`, {ns: 'about'})}
              </Link>
            </li>
          ))}
        </ul>
      </Stack>
    )}
    </>
  )
}

export default AboutLinks;