const AppSyncConfig =  {
    API: {
        GraphQL: {
          endpoint: 'https://qe7d45smdjdgfh6yhvlfn5x6uy.appsync-api.us-east-1.amazonaws.com/graphql',
          region: 'us-east-1',
          defaultAuthMode: 'apiKey',
          apiKey: 'da2-ffk3gf52qbbq7a77y5jpbwzdve'
        }
    }
};

export default AppSyncConfig;