
import {Box, Typography, Stack, Avatar} from "@mui/material"
import { useTranslation } from "react-i18next";
import Login from './Login'

const Footer = () => {    
    const { t } = useTranslation();
    return (

        <Box flex={1} p={2} color="white" position="sticky"
            sx={{ 
                    top: "100vh",
                    display:{xs:"block", md:"none"},
                    background: "url(/images/BlueBackground.jpg)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover"
                }}>
                    
            <Stack direction="row" spacing={2} p={2}  sx={{justifyContent: "center", display: "flex",  alignItems: "center"}}>                
                <Avatar alt="C V Andrews" src="/images/cvandrews2.jpg" sx={{width: 150, height: 150}} />                
                <Typography variant="caption">
                "{t("cvandrews_message", {ns: 'common'})}"
                </Typography>
            </Stack>
            <Box sx={{position: "absolute", bottom: 0, left: 1}}>
                <Login />
            </Box>
        </Box>
    )
}
export default Footer