import { gql } from '@apollo/client';

export const LIST_LESSONS = gql`
  query ListLessons ($filter: TableLessonsFilterInput) {
    listLessons(filter: $filter) {
      nextToken
      items {
        book_id
        from_chapter
        from_verse
        lesson_id
        s3_uri
        to_chapter
        to_verse
        upload_date
      }
    }
  }
`;