import { useState } from "react";
import { Stack,Box, Button, IconButton } from "@mui/material";
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import Modal from '@mui/material/Modal';
import styled from 'styled-components';
import aboutimages from "../data/aboutimages.json"
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { useTranslation } from "react-i18next";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import DashboardIcon from '@mui/icons-material/Dashboard';

const ViewImagesModal = styled(Modal)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
});

const ImageContainer = styled(Box)({
    position: 'relative',
    width: '80vw',
    height: '80vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black'
});

const FullscreenImage = styled('img')({
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'contain'
});
interface ViewImageProps {
    decade: string;
  }

function ViewImages({ decade }: ViewImageProps) {    
    const { t } = useTranslation();
    const [viewImagesGallery, setViewImagesGallery] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState<number | null>(null);

    const decadeItem = aboutimages.aboutimages.find (item => item.decade === decade)

    function openViewImagesDialog(){
        setViewImagesGallery(true);
    }

    const openFullscreenImage = (index: number) => {
        setCurrentImageIndex(index);
    };

    const closeViewImagesDialog = () => {
        setViewImagesGallery(false);
        setCurrentImageIndex(null);
    };

    const showNextImage = () => {
        if (decadeItem) {
            setCurrentImageIndex((currentImageIndex! + 1) % decadeItem.images.length);
        }
    };

    const showPreviousImage = () => {
        if (decadeItem) {
            setCurrentImageIndex((currentImageIndex! - 1 + decadeItem.images.length) % decadeItem.images.length);
        }
    };

    const returnToGalleryView = () => {
        setCurrentImageIndex(null);
    };

    return (
        <>
            {decadeItem?.images && decadeItem.images.length > 0 && (
            <Stack paddingTop={1} direction="row" flexWrap="nowrap">
                <Box component="img" src={decadeItem?.images[0]?.image}
                sx={{border: '1px solid #f0f0f0'
                    , width: "225px", height: "185px"
                    , objectFit: "cover", objectPosition: "center"
                    , display: 'inline-block', marginRight: "20px" }} >
                </Box>
                <Button  onClick={e=>openViewImagesDialog()} size="small"
                    sx={{display: "flex", flexDirection: "column", alignItems: "center"}}
                    startIcon={<PhotoLibraryIcon />}>
                        {t("view_images", {ns: 'about', image_count: `${decadeItem?.images?.length}`})}
                </Button>
            </Stack>
            )}
            
            <ViewImagesModal
                open={viewImagesGallery}
                onClose={closeViewImagesDialog}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >    
                {currentImageIndex === null ? (   
                    <Box sx={{ position: 'relative', backgroundColor: "black", padding: "4px" }}>
                        <IconButton onClick={closeViewImagesDialog} sx={{ position: 'absolute', top: '0px', right: '10px', color: 'white', zIndex: 10 }}>
                            <CloseIcon />
                        </IconButton>      
                        <ImageList cols={2}
                            variant="standard" 
                            sx={{ width: 500, height: 450, backgroundColor: "black", padding: "8px"}}>
                            <ImageListItem cols={2}>&nbsp; </ImageListItem>
                            {decadeItem?.images.map((item, index) => (
                                <ImageListItem sx={{ cursor: 'pointer' }} key={item.image} onClick={() => openFullscreenImage(index)}>
                                <img
                                    srcSet={`${item.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                    src={`${item.image}?w=248&fit=crop&auto=format`}
                                    loading="lazy"
                                    alt=""
                                />
                                <ImageListItemBar
                                    title={t(`${item.title}`, {ns: 'about'})}
                                />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </Box>
                ) : (
                    <ImageContainer>
                        <IconButton onClick={showPreviousImage} sx={{ position: 'absolute', top: '50%', left: '10px', color: 'white', zIndex: 10 }}>
                            <ArrowBackIosIcon />
                        </IconButton>
                        <FullscreenImage
                            src={decadeItem?.images[currentImageIndex].image}
                            alt=""
                        />
                        <ImageListItemBar
                            title={t(`${decadeItem?.images[currentImageIndex].title}`, { ns: 'about' })}
                            position="below"
                            sx={{ background: 'rgba(0, 0, 0, 0.7)', color: 'white' }}
                        />
                        <IconButton onClick={showNextImage} sx={{ position: 'absolute', top: '50%', right: '10px', color: 'white', zIndex: 10 }}>
                            <ArrowForwardIosIcon />
                        </IconButton>
                        <Button onClick={returnToGalleryView} sx={{ position: 'absolute', bottom: '10px', left: '10px', color: 'white', zIndex: 10 }}>
                            <DashboardIcon />
                        </Button>
                        <IconButton onClick={closeViewImagesDialog} sx={{ position: 'absolute', top: '10px', right: '10px', color: 'white', zIndex: 10 }}>
                            <CloseIcon />
                        </IconButton>
                    </ImageContainer>
                )}
            </ViewImagesModal>
        </>
        
    )
}

export default ViewImages;