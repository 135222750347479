import { Typography, Paper, Stack, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import TimelineIcon from '@mui/icons-material/Timeline';
import ViewImages from "./ViewImages";
import AboutLinks from "./AboutLinks";

  
function About() {
    const { t } = useTranslation();
    return (
        <Stack spacing={2}>
            <Paper sx={{ padding: 2, margin: 0, minHeight: "190px"}}>
                    <Box sx={{ color: (theme) => theme.palette.text.secondary}}>                        
                        <Typography variant="h4" gutterBottom><TimelineIcon />&nbsp;1950</Typography>
                    </Box>
                    <Box paddingTop={1}>
                        <Typography sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }} variant="body2">{t("1950_about", {ns: 'about'})}</Typography>
                    </Box>
                    <ViewImages decade="1950" />
                    <AboutLinks decade="1950" />
            </Paper>
            <Paper sx={{ padding: 2, margin: 0, minHeight: "190px"}}>
                    <Box sx={{ color: (theme) => theme.palette.text.secondary}}>                        
                        <Typography variant="h4" gutterBottom><TimelineIcon />&nbsp;1960</Typography>
                    </Box>
                    <Box paddingTop={1}>
                        <Typography sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }} variant="body2">{t("1960_about", {ns: 'about'})}</Typography>
                    </Box>
                    <ViewImages decade="1960" />
                    <AboutLinks decade="1960" />
            </Paper>
            <Paper sx={{ padding: 2, margin: 0, minHeight: "190px"}}>
                    <Box sx={{ color: (theme) => theme.palette.text.secondary}}>                        
                        <Typography variant="h4" gutterBottom><TimelineIcon />&nbsp;1970</Typography>
                    </Box>
                    <Box paddingTop={1}>
                        <Typography sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }} variant="body2">{t("1970_about", {ns: 'about'})}</Typography>
                    </Box>
                    <ViewImages decade="1970" />
                    <AboutLinks decade="1970" />
            </Paper>
            <Paper sx={{ padding: 2, margin: 0, minHeight: "190px"}}>
                    <Box sx={{ color: (theme) => theme.palette.text.secondary}}>                        
                        <Typography variant="h4" gutterBottom><TimelineIcon />&nbsp;1980</Typography>
                    </Box>
                    <Box paddingTop={1}>
                        <Typography sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }} variant="body2">{t("1980_about", {ns: 'about'})}</Typography>
                    </Box>
                    <ViewImages decade="1980" />
                    <AboutLinks decade="1980" />
            </Paper>
            <Paper sx={{ padding: 2, margin: 0, minHeight: "190px"}}>
                    <Box sx={{ color: (theme) => theme.palette.text.secondary}}>                        
                        <Typography variant="h4" gutterBottom><TimelineIcon />&nbsp;1990</Typography>
                    </Box>
                    <Box paddingTop={1}>
                        <Typography sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }} variant="body2">{t("1990_about", {ns: 'about'})}</Typography>
                    </Box>
                    <ViewImages decade="1990" />
                    <AboutLinks decade="1990" />
            </Paper>
            <Paper sx={{ padding: 2, margin: 0, minHeight: "190px"}}>
                    <Box sx={{ color: (theme) => theme.palette.text.secondary}}>                        
                        <Typography variant="h4" gutterBottom><TimelineIcon />&nbsp;2000</Typography>
                    </Box>
                    <Box paddingTop={1}>
                        <Typography sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }} variant="body2">{t("2000_about", {ns: 'about'})}</Typography>
                    </Box>
                    <ViewImages decade="2000" />
                    <AboutLinks decade="2000" />
            </Paper>
            <Paper sx={{ padding: 2, margin: 0, minHeight: "190px"}}>
                    <Box sx={{ color: (theme) => theme.palette.text.secondary}}>                        
                        <Typography variant="h4" gutterBottom><TimelineIcon />&nbsp;2010</Typography>
                    </Box>
                    <Box paddingTop={1}>
                        <Typography sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }} variant="body2">{t("2010_about", {ns: 'about'})}</Typography>
                    </Box>
                    <ViewImages decade="2010" />
                    <AboutLinks decade="2010" />
            </Paper>
            <Paper sx={{ padding: 2, margin: 0, minHeight: "190px"}}>
                    <Box sx={{ color: (theme) => theme.palette.text.secondary}}>                        
                        <Typography variant="h4" gutterBottom><TimelineIcon />&nbsp;2020</Typography>
                    </Box>
                    <Box paddingTop={1}>
                        <Typography sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }} variant="body2">{t("2020_about", {ns: 'about'})}</Typography>
                    </Box>
                    <ViewImages decade="2020" />
                    <AboutLinks decade="2020" />
            </Paper>
            <Paper sx={{ padding: 2, margin: 0, minHeight: "190px"}}>
                    <Box sx={{ color: (theme) => theme.palette.info.main}}>                        
                        <Typography variant="h4" gutterBottom><TimelineIcon />&nbsp;{t("present_time", {ns: 'about'})}</Typography>
                    </Box>
                    <Box paddingTop={1}>
                        <Typography sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}  variant="body2">{t("Present_about", {ns: 'about'})}</Typography>
                    </Box>
                    <ViewImages decade="Present" />
                    <AboutLinks decade="Present" />
            </Paper>
        </Stack>
    )
  }
  
  export default About;