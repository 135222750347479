import { Typography, Paper, Box, Button, Stack} from "@mui/material";
import { useParams } from 'react-router-dom'
import BookIndexNav from './BookIndexNav'
import AddLesson from './AddLesson'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import biblebooks from "../data/biblebooks.json"
import bibleimages from "../data/bibleimages.json"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Drawer from '@mui/material/Drawer';
import { useState, useEffect, useRef } from "react";
import ReactAudioPlayer from 'react-audio-player';
import { useTranslation } from "react-i18next";
import {useNavigate, useLocation } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { LIST_LESSONS } from "../gql/queries.graphql"
import { ListLessonsQuery, QueryListLessonsArgs} from "../gql/graphql"
import { useQuery } from '@apollo/client';
  
function Book() {
    const params = useParams()
    const bookId:string = params.bookId?params.bookId:""
    const book = biblebooks.books.find (item => item.id === bookId)
    const navigate = useNavigate();
    const location = useLocation();
    const bookImage = (bibleimages as { [key: string]: any })[bookId];
    const prevBook = book?.sort != null && book.sort > 0 ? 
        biblebooks.books.find (item => item.sort === book.sort-1) : null;
    const nextBook = book?.sort != null && book.sort < 66 ? 
            biblebooks.books.find (item => item.sort === book.sort+1) : null;
    const { data, refetch } = useQuery<ListLessonsQuery, QueryListLessonsArgs>(
        LIST_LESSONS, 
        {
            variables: {
            filter : {
                book_id: {
                eq: bookId
                }
            }
            }
        }
    );

    const lessonIds = data?.listLessons?.items?.map((lesson:any) => lesson.lesson_id);
    const hasLessons = lessonIds && lessonIds.length > 0;
    const maxLessonId = hasLessons ? Math.max(...lessonIds) : 0;
    
    const [open, setOpen] = useState(false);
    const [commentary, setCommentary] = useState("");
    const [audiofilename, setAudiofilename] = useState("");
    const { t } = useTranslation();

    const playCommentary = (name: string, s3_uri: string) => {
        setOpen(true);
        setCommentary(name);
        setAudiofilename(s3_uri);
    }

    const [descriptionStyle, setDescriptionStyle] = useState({
        textAlign: "justify",
        overflow: "hidden",
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '8',
        WebkitBoxOrient: 'vertical'
    });

    const [expanded, setExpanded] = useState(false);
    const handleExpand = () => {
        setExpanded(true);
        setDescriptionStyle({
            textAlign: "justify",
            overflow: "visible",
            textOverflow: 'ellipsis',
            display: 'inline',
            WebkitLineClamp: '8',
            WebkitBoxOrient: 'vertical'
        });
    };
    const handleCollapse = () => {
        setExpanded(false);
        setDescriptionStyle({
            textAlign: "justify",
            overflow: "hidden",
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '8',
            WebkitBoxOrient: 'vertical'
        });
    };

    const [isEllipsisPresent, setIsEllipsisPresent] = useState(false);
    const descriptionTextRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (descriptionTextRef.current) {
            const isEllipsis = descriptionTextRef.current.scrollHeight > descriptionTextRef.current.clientHeight;
            setIsEllipsisPresent(isEllipsis);
        }
    }, [location.pathname]);
    
    return (
        <>
                <Box pb={2}
                    sx={{display: "flex", flexDirection: "row", justifyContent: 'space-between'}}>
                    <Button sx = {{}} component="a" size="small" disabled={!prevBook}
                            onClick={() => {
                                if (prevBook != null){
                                  navigate('/cvab/book/' + prevBook.id);
                                }
                            }}
                        >
                        <NavigateBeforeIcon /> 
                        <Box sx={{display:{ xs: "none" , sm: "block"}}}>{t("previous", {ns: 'common'})} </Box>
                    </Button>
                    <BookIndexNav />
                    <Button component="a" size="small"  disabled={!nextBook}
                            onClick={() => {
                                if (nextBook != null){
                                  navigate('/cvab/book/' + nextBook.id);
                                }
                            }}>
                        <Box sx={{display:{ xs: "none" , sm: "block"}}}>{t("next", {ns: 'common'})}</Box>
                        <NavigateNextIcon />
                    </Button>
                </Box>
                <Paper
                    sx={{
                        padding: 2,
                        minHeight: "210px",
                        position: "relative"
                    }}
                    >
                        <Box
                            component="img"
                            src={bookImage?bookImage:bibleimages.DEFAULT}
                            alt={t(`${bookId}.imageTitle`, {ns: 'books'})}
                            sx={{
                                width: {xs: "100%", sm: "308px"},
                                height: {xs: "160px", sm: "206px"},
                                float: 'left',
                                display: 'inline-block',
                                marginRight: "20px"
                            }}
                        />
                        <Typography gutterBottom variant="h5" component="div">
                            {t(`${book?.id}.name`, {ns: 'books'})} 
                        </Typography>
                        <Box ref={descriptionTextRef} color="text.secondary"  sx={descriptionStyle}>
                            <Typography component="pre" variant="body2" 
                                sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>    
                                {t(`${book?.id}.description`, {ns: 'books'})}
                            </Typography>
                        </Box>
                        <Box sx={{ position: "absolute"
                                , display: isEllipsisPresent ? 'block' : 'none'
                                , bottom: "0"
                                , width: "100%"
                                , textAlign: "center"
                                , margin: "-2px"
                                , cursor: "pointer"}}>
                            {expanded ? <Button size="small" onClick={handleCollapse} endIcon={<ExpandLessIcon />}>{t("show_less", {ns: 'common'})}</Button> 
                                        : <Button size="small" onClick={handleExpand} endIcon={<ExpandMoreIcon />}>{t("show_more", {ns: 'common'})}</Button> }
                        </Box>
                    </Paper>

                <TableContainer sx={{ marginTop: "10px" }} component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell size="small" sx={{width: "15px"}}></TableCell>
                                <TableCell sx={{
                                    color: (theme) => theme.palette.text.secondary
                                }}>{t("audio_commentary", {ns: 'common'})}</TableCell>
                                <TableCell align="right" sx={{
                                    color: (theme) => theme.palette.text.secondary
                                }}>{t("uploaded", {ns: 'common'})}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                data?.listLessons?.items?.length ? (
                                data?.listLessons?.items?.map((lesson: any) => (
                                    <TableRow
                                    key={lesson.lesson_id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                    <TableCell size="small">
                                            <PlayCircleOutlineIcon color="primary" sx={{cursor: "pointer"}} 
                                                onClick={() => playCommentary(`${lesson.lesson_id}`, `${lesson.s3_uri}`)}/>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                    {t("lesson", {ns: 'common'})} {lesson.lesson_id}:&nbsp;&nbsp;
                                    {lesson.lesson_id === 1 ? 
                                        t("introduction", {ns: 'common'}) : 
                                        t("chapter_broken", {ns: 'common'
                                                , chapter_from: `${lesson.from_chapter}`
                                                , verse_from: `${lesson.from_verse}`
                                                , chapter_to: `${lesson.to_chapter}`
                                                , verse_to: `${lesson.to_verse}`
                                                }) 
                                    }
                                    </TableCell>
                                    <TableCell size="small" align="right" sx={{
                                        color: (theme) => theme.palette.text.secondary
                                    }}>{lesson.upload_date}</TableCell>
                                    </TableRow>
                                ))
                                ) : (
                                    <TableRow
                                        key="NoLessons"
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}                                
                                    >
                                        <TableCell size="small">&nbsp;
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Typography  variant="body2" color="text.secondary" >
                                            {t("lessons_coming_soon", {ns: 'common'})}
                                            </Typography>
                                        </TableCell>
                                        <TableCell size="small" align="right" sx={{
                                            color: (theme) => theme.palette.text.secondary
                                        }}>&nbsp;</TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>

                <AddLesson bookId={bookId} maxLessonId={maxLessonId} onUploadSuccess={refetch} />

                <Drawer open={open} anchor={"bottom"} onClose={() => setOpen(false)}>
                    <Stack direction="row" spacing={2} p={2}  sx={{justifyContent: "center"}}>
                        <Box >
                            <ReactAudioPlayer
                                src={"https://media.cvandrewsbible.com/audio/"+ audiofilename}
                                autoPlay                         
                                controls
                            />
                            <Typography variant="body2" component="div" 
                                sx={{textAlign: "center"}}>
                                {t(`${book?.id}.name`, {ns: 'books'})}:  {t("lesson", {ns: 'common'})} {commentary}
                            </Typography>
                        </Box>
                    </Stack>
                </Drawer>
        </>
    )
  }
  
  export default Book;