import { Typography, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

function Contact() {
    const { t } = useTranslation();
    return (
        <Paper sx={{padding: 2}}>
            <Typography variant="body2">{t('contact_me', {ns: 'contact'})}</Typography>
            <br/>
             
            <Table size="small" aria-label="a dense table">
                <TableBody>
                    <TableRow>
                        <TableCell ><ContactMailIcon /></TableCell>
                        <TableCell >P. O. Box 464905 <br/>Lawrenceville, GA 30042, USA</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell ><ContactPhoneIcon /></TableCell>
                        <TableCell >+16784724224</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell ><ForwardToInboxIcon /></TableCell>
                        <TableCell >pastorcvandrews@gmail.com</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Paper>
    )
  }
  
  export default Contact;